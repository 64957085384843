<template>
  <div class="my-md">
    <div class="container col-md-8 col-12 video-main">
      <!-- Left Triangle -->
      <div class="video-left-triangle d-lg-block d-none">
        <img
          src="../../../public/img/stm/gradient-triangle.svg"
          alt="Left Image"
        />
      </div>

      <!-- Main Image with Play Button -->
      <div
        v-if="!play_video"
        class="position-relative"
        style="width: 100%; height: auto"
      >
        <img
          class="img-fluid"
          src="../../../public/img/stm/video-img.png"
          alt="Main Image"
          style="display: block"
        />
        <img
          class="position-absolute cursor-pointer"
          src="../../../public/img/stm/play.svg"
          style="
            width: 80px;
            height: 80px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
          "
          alt="Play Image"
          @click="playVideo()"
        />
      </div>

      <div v-else class="center">
        <iframe
          width="1000"
          height="500"
          src="https://www.youtube.com/embed/R-3OYLPhZY4?autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          style="z-index: 9999"
        ></iframe>
      </div>

      <!-- Right Triangle -->
      <div class="video-right-triangle d-lg-block d-none">
        <img
          src="../../../public/img/stm/gradient-triangle2.svg"
          alt="Right Image"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      play_video: false,
    };
  },
  methods: {
    playVideo() {
      this.play_video = !this.play_video;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";

.video-main {
  position: relative;
  z-index: 1;
}
.video-left-triangle {
  position: absolute;
  left: -9px;
  top: -21px;
  z-index: 1;
}
.video-right-triangle {
  position: absolute;
  right: -5px;
  bottom: -22px;
  z-index: 1;
}
</style>
