<template>
  <div class="f-primary container-fluid f-header-1">
    <div
      class="header-wrapper-1 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center"
    >
      <!-- Logo (clickable) -->
      <div class="logo-mod" @click="$router.push('/web/home')">
        <img
          src="/img/new_tf_logo.png"
          alt="Talent Find Logo"
          class="img-fluid"
        />
      </div>

      <!-- Social icons and text -->
      <div
        class="social-text d-flex flex-column flex-md-row align-items-start align-items-md-center mt-2 mt-md-0"
      >
        <div class="social-icons d-flex align-items-center">
          <!-- Facebook (inline SVG, only the "f" shape) -->
          <div
            class="icon-circle"
            @click="routeURL('https://www.facebook.com/talentfindsolutions/')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                d="M9 8H7V12H9V24H13V12H15.76L16 8H13V6.2C13 5.42 13.16 5 14 5H16V1H13.48C10.67 1 9 2.66 9 5.16V8Z"
              />
            </svg>
          </div>

          <!-- LinkedIn (inline SVG, only the "in" shape) -->
          <div
            class="icon-circle"
            @click="routeURL('https://www.linkedin.com/company/talentfind-solutions/')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                d="M4.98 3C3.88 3 3 3.88 3 4.98C3 6.07 3.88 6.98 4.98 6.98C6.07 6.98 6.98 6.07 6.98 4.98C6.98 3.88 6.07 3 4.98 3ZM3.5 8.5H6.5V20.5H3.5V8.5ZM9.5 8.5H12.3V10H12.35C12.78 9.22 13.77 8.4 15.3 8.4C18.4 8.4 19 10.33 19 13.1V20.5H16V13.8C16 12.3 15.64 11.4 14.5 11.4C13.27 11.4 12.5 12.3 12.5 13.8V20.5H9.5V8.5Z"
              />
            </svg>
          </div>
        </div>

        <p class="mb-0 mt-2 mt-md-0">©2025 TalentFind All rights reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    routeURL(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/stm.scss";

/* Header Container */
.f-header-1 {
  height: 90px;
  padding: 0 0px;
  margin-top: 30px;
}

/* Social Icons Container */
.social-icons {
  margin-right: 10PX;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px; /* spacing between circles */
}

/* Purple circle background */
.icon-circle {
  width: 35px;
  height: 35px;
  background-color: #936ff2; /* Purple */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  /* Make the SVG icon white */
  svg {
    color: #fff; /* uses fill="currentColor" in the SVG */
    width: 20px;
    height: 20px;
  }
}



/* Responsive (Mobile) */
@media (max-width: 767.98px) {
  .f-header-1 {
    height: auto;
    padding: 40px 2px;
  }
  .header-wrapper-1 {
    flex-direction: column;
    align-items: flex-start;
  }
  .social-text {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px !important;
  }
  .logo-mod img {
    max-width: 140px;
  }
}
</style>
