<template>
  <div>
    <section class="main home-section margin-top-150 mt-5 text-white">
      <div class="d-flex flex-column-reverse flex-md-row">
        <div
          class="d-flex flex-column justify-content-center align-items-start p-3"
        >
          <h1 class="f-secondary t-secondary font-60 p-2">Shape Your Future Today!</h1>
          <div class="f-primary p-2 line-height-30 mb-3">
            Join our community of healthcare professionals and organisations
            committed to making a difference.Sign up now to begin your journey
            toward a fulfilling career.
          </div>
          <div class="d-flex justify-content-start p-2">
            <button class="btn btn-primary" @click="goToRegister()">
              Register Now
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 margin-top-n-45">
          <img src="../../../public/img/stm/cta-banner.png" class="img-fluid" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { routeToWebRegister } from "../../helpers/helper";

export default {
  methods: {
    goToRegister() {
      routeToWebRegister(this.$router);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
