<template>
  <div v-if="isOpen" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <button class="close-btn btn-outline" @click="closeModal">✖</button>
      <section class="main">
        <h1 class="f-secondary text-dark-primary">
          {{ article?.title }}
        </h1>
        <p>{{ article?.description }}</p>
        <div class="text-center">
          <img :src="article?.image" class="img-fluid border-radius-40" />
        </div>

        <div>
          <!-- Share starts-->
          <div
            class="my-5 d-flex justify-content-between align-items-center mx-auto"
          >
            <div class="d-flex align-items-center">
              <img
                class="circle-img"
                src="../../../public/img/stm/article-writer.png"
              />
              <div class="d-inline-flex flex-column ml-3">
                <h4 class="bold">By {{ article?.postedBy }}</h4>
                <small> {{ article?.dateTime }}</small>
              </div>
            </div>
            <div class="d-flex align-items-center flex-column">
              <h5 class="mb-0 mb-2">Share</h5>
              <div>
                <a
                  href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Ftalentfind.com%2Fhow-to-overcome-the-challenges-of-a-healthcare-career-transition"
                  class="mr-2"
                >
                  <img src="../../../public/img/stm/facebook.svg" />
                </a>
                <a
                  href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2FshareArticle%3Furl%3Dhttps%253A%252F%252Ftalentfind.com%252Fhow-to-overcome-the-challenges-of-a-healthcare-career-transition"
                >
                  <img src="../../../public/img/stm/linkedin.svg" />
                </a>
              </div>
            </div>
          </div>
          <div class="my-3">
            <hr />
          </div>
          <!-- Share ends -->

          <!-- Content -->
          <component :is="article?.contentFileName" />
          <!-- Content ends -->

          <!-- Share starts -->
          <div class="d-flex align-items-start flex-column">
            <h5 class="mb-0 mb-2">Share</h5>
            <div>
              <a
                href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Ftalentfind.com%2Fhow-to-overcome-the-challenges-of-a-healthcare-career-transition"
                class="mr-2"
              >
                <img src="../../../public/img/stm/facebook.svg" />
              </a>
              <a
                href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2FshareArticle%3Furl%3Dhttps%253A%252F%252Ftalentfind.com%252Fhow-to-overcome-the-challenges-of-a-healthcare-career-transition"
              >
                <img src="../../../public/img/stm/linkedin.svg" />
              </a>
            </div>
          </div>
          <div class="my-3">
            <hr />
          </div>
          <!-- Share end -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import articles from "./articles.json";
import Article1Content from "./Article1Content.vue";
import Article2Content from "./Article2Content.vue";
import Article3Content from "./Article3Content.vue";

export default {
  components: {
    Article1Content,
    Article2Content,
    Article3Content,
  },
  props: {
    isOpen: Boolean,
    articleId: Number,
  },
  data() {
    return {
      article: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    fetchArticle() {
      if (
        !isNaN(this.articleId) &&
        this.articleId > 0 &&
        this.articleId <= articles.length
      ) {
        this.article = articles[this.articleId - 1] || null;
      } else {
        this.article = null;
      }
    },
  },
  watch: {
    articleId: "fetchArticle",
    isOpen(newVal) {
      if (newVal) {
        this.fetchArticle();
      }
    },
  },
  mounted() {
    this.fetchArticle();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
}

/* Modal content */
.modal-content {
  background: white;
  padding: 65px;
  border-radius: 10px;
  width: 60%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  text-align: left;
}

/* Close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
</style>
