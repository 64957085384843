<template>
  <div>
    <section class="main px-4" style="margin-bottom: 150px">
      <div v-if="$route.path.includes('/web/home') ? true : false">
        <div class="d-flex justify-content-between">
          <h1 class="f-secondary font-768 text-gradient">
            News from your global healthcare community
          </h1>
          <div>
            <button class="btn btn-primary" @click="$router.push('/web/news')">
              All News
            </button>
          </div>
        </div>
        <p>
          Explore the latest news and updates across the global health and
          social care community.
        </p>
      </div>
      <div v-else>
        <h1 class="f-secondary text-gradient mb-3" v-if="!hide">
          Latest Articles
        </h1>
      </div>
      <div>
        <div class="row">
          <div
            v-for="(article, index) in articles"
            :key="index"
            class="card col-12 col-md-6 col-lg-4 cursor-pointer"
            @click="showArticle(index)"
          >
            <img
              :src="article?.image"
              class="card-img-top border-radius-40 mt-5"
              :alt="article?.altText"
            />
            <div class="mt-3 card-body">
              <p class="f-secondary text-dark font-25 bold">
                {{ article?.title }}
              </p>
              <p>{{ article?.description }}</p>
            </div>
            <div class="d-flex align-items-center card-footer">
              <img
                class="circle-img"
                src="../../../public/img/stm/article-writer.png"
              />
              <div class="d-inline-flex flex-column ml-3">
                <span class="bold">{{ article?.postedBy }}</span>
                <small> {{ article?.dateTime }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ModalView
      :articleId="articleId"
      :isOpen="showModal"
      @close="showModal = false"
    />
    <ScrollToTop />
  </div>
</template>

<script>
import ModalView from "./ModalView.vue";
import ScrollToTop from "../../components/reusable/ScrollToTop.vue";


export default {
  components: {
    ModalView,
    ScrollToTop,
  },
  props: {
    hide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      articleId: null,
      articles: [
        {
          image: "/img/stm/article1.jpg",
          altText: "Article 1",
          title: "The power of cross-border mentorship in healthcare",
          description:
            "Healthcare is one of the most continually evolving sectors out there, with new research and innovations happening all the time to improve patient care.",
          postedBy: "Talentfind",
          dateTime: "16 January 2025 • 9 min read",
        },
        {
          image: "/img/stm/article2.png",
          altText: "Article 2",
          title:
            "How to overcome the challenges of a healthcare career transition",
          description:
            "Our sector is growing year on year, and as a result, people across the globe are considering making the transition into healthcare",
          postedBy: "Talentfind",
          dateTime: "8 January 2025 • 9 min read",
        },
        {
          image: "/img/stm/article3.jpg",
          altText: "Article 3",
          title: "How digital learning could end the health workforce crisis",
          description:
            "The health sector is facing a workforce crisis, with The World Health Organisation (WHO) estimating that there will be a shortfall of 10 million health workers by 2030.",
          postedBy: "Talentfind",
          dateTime: "18 December 2024 • 10 min read",
        },
      ],
    };
  },
  computed: {
    isUAE() {
      return this.$route.path.includes("/web/uae");
    },
  },
  methods: {
    showArticle(index) {
      if (this.isUAE) {
        this.articleId = index + 1;
        this.showModal = true;
      } else {
        this.$router.push(`/web/article/${index + 1}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
